import React from 'react'
import Slider from 'react-slick'
import { withPrefix } from 'gatsby-link'
import { StaticImage } from "gatsby-plugin-image";

const SLIDES = [
  {
    img_big: '1.jpg',
    img_small: '1_800w.jpg',
    subtitle: 'Heerlijk vers',
    title: 'Croissants',
  },
  {
    img_big: '2.jpg',
    img_small: '2_800w.jpg',
    subtitle: 'Ambachtelijk bereid',
    title: 'Gebak',
  },
  {
    img_big: '3.jpg',
    img_small: '3_800w.jpg',
    subtitle: 'Lekker limburgs',
    title: 'Vlaaien',
  }
  // ,
  // {
  //   img_big: '4.jpg',
  //   img_small: '4_800w.jpg',
  //   subtitle : 'Verschillende soorten',
  //   title: 'Vlaaien',
  // },
]

export const GetImage = (name) => {
  switch(name) {
    case '1.jpg':
      return () => (
        <StaticImage 
          className="img-responsive"
          src={'../../images/slider-big/1.jpg'}
          layout="fullWidth"
          transformOptions={{fit:'contain'}}
          alt="slide"
        />
      )
    case '2.jpg':
      return () => (
        <StaticImage 
          className="img-responsive"
          src={'../../images/slider-big/2.jpg'}
          layout="fullWidth"
          transformOptions={{fit:'contain'}}
          alt="slide"
        />
      )
    case '3.jpg':
      return () => (
        <StaticImage 
          className="img-responsive"
          src={'../../images/slider-big/3.jpg'}
          layout="fullWidth"
          transformOptions={{fit:'contain'}}
          alt="slide"
        />
      )
    case '4.jpg':
      return () => (
        <StaticImage 
          className="img-responsive"
          src={'../../images/slider-big/3.jpg'}
          layout="fullWidth"
          height={800}
          transformOptions={{fit:'contain'}}
          alt="slide"
        />
      )
  }
}

class SliderMain extends React.Component {
  render() {
    const slides = SLIDES.map((slide) => {
      const SlideImage = GetImage(slide.img_big);
      return (
        <div key={slide.img_big} className="slider-big__slide">
          <picture>
            <source
              media="(max-width: 800px)"
              srcSet={withPrefix('slider-big/' + slide.img_small)}
            />
            <SlideImage />
          </picture>
          <div className="slider-big__content">
            <div className="container container--full">
              <div>
                <h2 className="heading-highlight m0 slick-anime slick-anime--heading1">
                  {slide.title}
                </h2>
              </div>
              {slide.subtitle && (
                <span className="heading-highlight-red m0 slick-anime slick-anime--heading2">
                  {slide.subtitle}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    )

    return <div>{slides && <Slider {...this.props}>{slides}</Slider>}</div>
  }
}

export default SliderMain
