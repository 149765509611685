import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Testimonial = ({ image, html }) => {
  return (
    <blockquote className="testimonial">
      <div className="testimonial__img">
        <Img className="image-round el-center" fluid={image} alt="image" />
      </div>
      <div className="testimonial__content">
      <div className="fb-page" data-href="https://www.facebook.com/biechristel/" data-tabs="timeline" data-width="500200" data-height="200" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
      <blockquote cite="https://www.facebook.com/biechristel/" className="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/biechristel/">Luxe bakkerij Bie Christel</a></blockquote></div>
      </div>
    </blockquote>
    
  )
}

Testimonial.propTypes = {
  image: PropTypes.object.isRequired,
  html: PropTypes.string.isRequired,
}

export default Testimonial
